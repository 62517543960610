<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  name: "bar-chart",
  props: ["options"],
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartData() {
      this.$data._chart.update();
    },
  },
};
</script>