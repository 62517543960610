<style lang="scss" scoped>
.card-info {
  .v-card__subtitle,
  .v-card__text {
    text-align: center;
  }
  .v-card__subtitle {
    // border-top: 5px solid #447498;
  }
  span,
  .subtitle {
    color: #447498;
  }
}
.v-card__title {
  color: #447498 !important;
}
</style>
<template>
  <div style="max-width: 1500px" class="mx-auto px-5">
    <v-row>
      <v-col class="text-right">
        <v-toolbar color="#447498" dark>
          <h2>{{ __('Painel de Controle') }}</h2>
          <v-spacer></v-spacer>
          <v-btn @click="getAnalytcs" icon :loading="loading">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row class="mb-2">
      <v-col cols="6" md="3">
        <v-card outlined class="card-info">
          <v-card-subtitle class="subtitle">{{
            __('Total')
          }}</v-card-subtitle>
          <v-card-text class="text-h3">
            <span>{{ total_leads }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="3">
        <v-card outlined class="card-info">
          <v-card-subtitle class="subtitle">{{
            __('Novas')
          }}</v-card-subtitle>
          <v-card-text class="text-h3">
            <span>{{ new_leads_count }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="3">
        <v-card outlined class="card-info">
          <v-card-subtitle class="subtitle">{{
            __('Finalizadas')
          }}</v-card-subtitle>
          <v-card-text class="text-h3">
            <span>{{ finished_leads_count }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" md="3">
        <v-card outlined class="card-info">
          <v-card-subtitle class="subtitle">{{
            __('Recusadas')
          }}</v-card-subtitle>
          <v-card-text class="text-h3">
            <span>{{ refused_leads_count }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row style="flex-wrap: nowrap;"> -->
    <v-row>
      <v-col cols="12" md="7" lg="8">
        <v-card outlined>
          <v-card-title>{{
            __('Leads por status')
          }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <bar-chart :chart-data="chartdata" :options="bars_options" />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="5" lg="4" heigth="400">
        <v-card outlined>
          <v-card-title>{{
            __('Tipos')
          }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <donut-chart :chart-data="donutchart" :options="options" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title>{{
            __('Média de permanência por status vermelho')
          }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <horizontal-bar-chart
              :chart-data="red_status_average"
              :options="bars_options"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined>
          <v-card-title>{{
            __('Média de permanência por status laranja')
          }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <horizontal-bar-chart
              :chart-data="orange_status_average"
              :options="bars_options"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DonutChart from "../components/charts/DonutChart";
import BarChart from "../components/charts/BarChart";
import HorizontalBar from "../components/charts/HorizontalBar";
import { mapState, mapActions } from "vuex";
import i18n from '@/mixins/i18n';

export default {
  name: "dashboard",
  mixins: [i18n],
  components: {
    DonutChart,
    BarChart,
    "horizontal-bar-chart": HorizontalBar
  },
  data: () => {
    return {
      i18n_prefix: 'dashboard',
      total_leads: 0,
      new_leads_count: 0,
      finished_leads_count: 0,
      refused_leads_count: 0,
      chartdata: {
        labels: [],
        datasets: []
      },
      red_status_average: {
        labels: [],
        datasets: []
      },
      orange_status_average: {
        labels: [],
        datasets: []
      },
      donutchart: {
        labels: [],
        datasets: []
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          align: "center",
          fullWidth: true,
          labels: {}
        }
      },
      bars_options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "bottom",
          align: "start",
          fullWidth: true
        },
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      },
      lead_status_duration: [],
      loading: false
    };
  },
  methods: {
    ...mapActions("lead_statuses", ["loadStatuses"]),
    getLeadQtyByStatus(data, status_code) {
      const lead_status = data.find(lead => lead.current_status_id === status_code);
      if (!lead_status) return 0;

      return lead_status.leads;
    },
    async getAnalytcs() {
      this.loading = true;
      try {
        const response = await this.$http.get("/dashboard/leads");
        const data = response.data;

        this.total_leads = data.total;
        this.new_leads_count = this.getLeadQtyByStatus(
          data.leads_per_status,
          1
        );
        this.finished_leads_count = this.getLeadQtyByStatus(
          data.leads_per_status,
          15
        );
        this.refused_leads_count = this.getLeadQtyByStatus(
          data.leads_per_status,
          2
        );

        await this.loadStatuses();

        this.chartdata = {
          labels: data.leads_per_status.map(status =>
            this.getStatusNameById(status.current_status_id)
          ),
          datasets: [
            {
              label: "Leads",
              backgroundColor: "rgb(41, 87, 123, 1)",
              minBarLength: 0,
              data: data.leads_per_status.map(status => status.leads),
              xAxisID: 0
            }
          ]
        };

        this.donutchart = {
          labels: data.leads_per_type.map(status => status.description),
          datasets: [
            {
              label: "Status",
              backgroundColor: ["#29577B", "#35C09C"],
              data: data.leads_per_type.map(status => status.leads)
            }
          ]
        };

        this.$store.commit("sendMessage", {
          text: this.__('Dados atualizado')
        });
      } catch (error) {
        this.$store.commit("sendMessage", {
          text: this.__('Falha ao carregar dados'),
          color: "red"
        });
      } finally {
        this.loading = false;
      }
    },
    getColor(color, opacity = 1) {
      switch (color) {
        case "red":
          return `rgb(244, 67, 54, ${opacity})`;
        case "green":
          return `rgb(60, 126, 119, ${opacity})`;
        case "orange":
          return `rgb(255, 152, ${opacity})`;
        default:
          return color;
      }
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async getAvarageStatusDuration() {
      const response = await this.$http.get(
        "/dashboard/avarage_status_duration"
      );

      const data = response.data;

      this.lead_status_duration = response.data;

      const red_status = data.filter(status => status.status_color == "red");

      this.red_status_average = {
        labels: red_status.map(status => status.name),
        datasets: [
          {
            label: this.__('Duração (em dias)'),
            backgroundColor: "#29577B",
            data: red_status.map(status => status.avarage)
          }
        ]
      };

      const orange_status = data.filter(
        status => status.status_color == "orange"
      );

      this.orange_status_average = {
        labels: orange_status.map(status => status.name),
        datasets: [
          {
            label: this.__('Duração (em dias)'),
            backgroundColor: "#35C09C",
            data: orange_status.map(status => status.avarage)
          }
        ]
      };
    },
    getStatusNameById(status_id) {
      return this.lead_statuses.find(status => status.id == status_id)?.name;
    }
  },
  computed: {
    ...mapState("lead_statuses", ["lead_statuses"])
  },
  created() {
    this.getAnalytcs();
    this.loadStatuses();
    this.getAvarageStatusDuration();
  }
};
</script>
